import React, { ReactElement } from 'react'
import './ErrorPage.scss'

const ErrorPage = (): ReactElement => {
  return (
    <div className="error-container">
      Something went wrong, Please try again later.
    </div>
  )
}

export default ErrorPage
